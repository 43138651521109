.custom-scrollbar-container {
  ::-webkit-scrollbar {
    width: 5px;
  }
  ::-webkit-scrollbar-thumb {
    background-color: #bbbbbb;
    border-radius: 5px;
  }
  ::-webkit-scrollbar-track {
    background-color: #f1f1f1;
  }
  scrollbar-width: thin;
  scrollbar-color: #0569ab #f1f1f1;
}

.custom-file-input-container {
  position: relative;
  display: inline-block;
}

.custom-file-input {
  position: absolute;
  opacity: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  cursor: pointer;
}

.custom-file-label {
  display: inline-block;
  background-color: #198754;
  color: white;
  padding: 0.5rem 1rem;
  border-radius: 0.25rem;
  cursor: pointer;
  user-select: none;
}

.custom-file-label:hover {
  background-color: #157347;
}

.loader {
  background: rgba(0, 0, 0, 0.834);
  bottom: 0;
  height: 100%;
  left: 0;
  overflow: hidden !important;
  position: fixed;
  right: 0;
  top: 0;
  width: 100%;
  z-index: 99999;
  text-align: center;
}
.clear-loading {
  text-align: center;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-left: -50px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  -ms-box-sizing: border-box;
  -o-box-sizing: border-box;
  display: inline-block;
  text-align: center;
  transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
}
/*Loading Effect */
.loading-effect {
  width: 100px;
  height: 100px;
}
.loading-effect > span,
.loading-effect > span:before,
.loading-effect > span:after {
  content: "";
  display: block;
  border-radius: 50%;
  border: 3px solid #00a9da;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
.loading-effect > span {
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  border-left-color: transparent;
  -webkit-animation: effect 2s infinite linear;
  -moz-animation: effect 2s infinite linear;
  -ms-animation: effect 2s infinite linear;
  -o-animation: effect 2s infinite linear;
  animation: effect 2s infinite linear;
}
.loading-effect > span:before {
  width: 75%;
  height: 75%;
  border-right-color: transparent;
}
.loading-effect > span:after {
  width: 50%;
  height: 50%;
  border-bottom-color: transparent;
}
@-webkit-keyframes effect {
  from {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@-o-keyframes effect {
  from {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@-ms-keyframes effect {
  from {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@-moz-keyframes effect {
  from {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes effect {
  from {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.swal2-confirm {
  background-color: #0569ab;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.error-content {
  text-align: center;
}

.error-content .notfound-404 h1 {
  font-size: calc(10rem + 10vw);
  font-weight: 900;
  background: url(/public/images/oops-bg.jpg) no-repeat;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-size: cover;
  background-position: center;
}
.error-content h3 {
  font-weight: 600;
  font-size: 25px;
  margin-bottom: 15px;
}
.error-content p {
  max-width: 550px;
  margin: 0 auto 25px;
}
.nav-item:hover {
  cursor: pointer;
}
@media only screen and (max-width: 767px) {
  .error-content .notfound-404 h1 {
    font-size: calc(6rem + 8vw);
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .error-content .notfound-404 h1 {
    font-size: calc(8rem + 9vw);
  }
}
